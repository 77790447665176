body {
	min-width: 320px;
}

body:has(dialog[open]) {
	overflow: hidden;
}

@font-face {
	font-family: "made_mirage";
	font-style: normal;
	font-weight: 400;
	src: url("/fonts/web/made_mirage_medium-webfont.woff2") format("woff2"),
		url("/fonts/web/made_mirage_medium-webfont.woff") format("woff");
	font-display: swap;
}

@font-face {
	font-family: "object_sans";
	font-style: normal;
	font-weight: 300;
	src: url("/fonts/web/PPObjectSans-Light.woff2") format("woff2"),
		url("/fonts/web/PPObjectSans-Light.woff") format("woff");
	font-display: swap;
}

@font-face {
	font-family: "object_sans";
	font-style: normal;
	font-weight: 400;
	src: url("/fonts/web/PPObjectSans-Regular.woff2") format("woff2"),
		url("/fonts/web/PPObjectSans-Regular.woff") format("woff");
	font-display: swap;
}

@font-face {
	font-family: "object_sans";
	font-style: normal;
	font-weight: 500;
	src: url("/fonts/web/PPObjectSans-Medium.woff2") format("woff2"),
		url("/fonts/web/PPObjectSans-Medium.woff") format("woff");
	font-display: swap;
}
